.swiper-container {
    width: 100%;
}

.custom-swiper .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-image {
    max-width: 100%;
    max-height: 100%;
}

.swiper-pagination-bullet {
    background-color: #000;
}

.swiper-pagination {
    z-index: 40;
    bottom: 10px;
}

.swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
}

.swiper-pagination-bullet-active {
    background-color: #000;
}
